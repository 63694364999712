.singleCardWrapper {
  width: 3.75rem;
  padding: 0.04rem 0.12rem 0.12rem;
  background-color: #fff;
}

.MultiCardWrapper {
  width: 100%;
  padding: 0.04rem 0.12rem 0.06rem 0.12rem;
  background-color: #fff;
  display: inline-flex;
  overflow-x: scroll;
  position: relative;
  top: -0.06rem;
}

.MultiCardWrapper::-webkit-scrollbar {
  display: none;
}

.MultiCardWrapper .MultiCard {
  width: 3.43rem;
  flex-shrink: 0;
  margin-right: 0.08rem;
}

.productModelSectionCard {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 0.04rem rgba(0, 0, 0, 0.1);
  border-radius: 0.04rem;
  padding: 0.04rem 0.08rem;
  position: relative;
}

.productModelSectionCard .modelImageBox {
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 0.08rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.productModelSectionCard .modelImage {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
}

.productModelSectionCard .modelInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #666666;
  padding-left: 0.48rem;
}

.productModelSectionCard .modelBody {
  display: flex;
  flex-wrap: wrap;
}

.productModelSectionCard .modelBody > div:not(:last-child)::after {
  content: ', ';
  margin-right: 0.04rem;
}

.productModelContent {
  width: 100%;
}

.productModelContent .modelCard {
  display: flex;
  align-items: center;
  height: 0.66rem;
  margin-bottom: 0.2rem;
}

.productModelContent .modelImage {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}

.productModelContent .modelInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral900);
}

.productModelContent .modelBody {
  display: flex;
  flex-wrap: wrap;
}

.productModelContent .modelBody > div:not(:last-child)::after {
  content: ', ';
  margin-right: 0.06rem;
}