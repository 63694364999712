.section {
  position: relative;
  background: white;
  padding: 0.09rem 0.12rem;
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: 500;
  line-height: 0.2rem;
}

.section.isDesktop {
  font-size: 0.16rem;
}

.section::after {
  content: "\e668";
  position: absolute;
  right: 0.12rem;
  width: 0.18rem;
  height: 0.18rem;
  line-height: 0.18rem;
  text-align: center;
  font-weight: 400;
  color: var(--gray5);
}

.descContentWrapper {
  display: table;
}

.descName {
  width: 1.2rem;
  display: table-cell;
  padding-right: 0.23rem;
  padding-bottom: 0.15rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  mix-blend-mode: normal;
}

.descValue {
  display: table-cell;
  padding-bottom: 0.15rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.descWrapper {
  width: 3.75rem;
  background: white;
  padding: 0.12rem 0.12rem var(--fixed-footer-bottom) 0.12rem;
}

.descWrapper .descTitle {
  font-size: 0.14rem;
  line-height: 0.4rem;
  text-align: center;
  border-bottom: 0.01rem solid var(--gray);
  margin-bottom: 0.15rem;
}

.descWrapper .descContent {
  height: 80vh;
  overflow-y: scroll;
}

.descWrapper .descContent::-webkit-scrollbar {
  display: none;
}

.descWrapper p {
  font-size: 0.13rem;
}

.descWrapper ul {
  list-style: disc inside;
}

.descWrapper ul li {
  font-size: 0.13rem;
}

.descWrapper img {
  width: 100%;
  height: auto;
}