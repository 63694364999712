.wrapper {
  background: white;
  position: relative;
  border-top: 0.08rem solid #F5F5F5;
}

.wrapper.isDesktop {
  border-top: 0;
}

.wrapper .header .title {
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: 500;
  line-height: 0.2rem;
  padding: 0.09rem 0 0 0.12rem;
}

.wrapper.isDesktop .header .title {
  font-size: 0.16rem;
}

.wrapper .images {
  height: 1.65rem;
  margin-top: 0.07rem;
  padding-left: 0.12rem;
}

.wrapper .images.transparent {
  opacity: 0;
}

.wrapper .images:not(.hasImages) {
  display: none;
}

.wrapper .postWrapper .image {
  width: 1.09rem;
  height: 1.65rem;
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .images .postWrapper.box {
  margin-right: 0.08rem !important;
}

.wrapper .postWrapper.transparent .image {
  opacity: 0;
}

.wrapper .bottomLine {
  height: 0.12rem;
  width: 100%;
}

.wrapper .review {
  margin-top: 0.08rem;
}

.wrapper .review .seeAllReviews {
  width: 2rem;
  height: 0.3rem;
  font-weight: 400;
  font-size: 0.12rem;
  display: block;
  margin: 0 auto;
  line-height: 0.3rem;
  text-align: center;
  color: var(--neutral900);
  background: #F5F5F5;
  border-radius: 0.22rem;
  cursor: pointer;
}

.wrapper .review .nickname {
  font-size: 0.14rem;
  line-height: 0.2rem;
}

.wrapper .review .header {
  padding: 0;
}

.wrapper .review .desc {
  font-size: 0.12rem;
  line-height: 0.16rem;
}

.wrapper .review .content {
  font-size: 0.14rem;
  line-height: 0.2rem;
  font-weight: 400;
  color: var(--neutral900);
}

.wrapper .review .contentLimit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.wrapper .review .contentWrapper {
  margin-top: 0.1rem;
}

.wrapper .review .reviewItemsWrapper {
  padding: 0 0.12rem;
}

.wrapper .review .reviewItemsClass {
  padding: 0.12rem 0;
}

.wrapper .review .reviewItemBottom {
  border-bottom: 0.01rem solid #f5f5f5;
}

.postWrapper.inDesktop .previewImage {
  width: 5.21rem;
}

.postWrapper .previewImage {
  width: 3.75rem;
  height: auto;
  margin: 0 auto;
  display: block;
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.postImageContent.inDesktop {
  width: 5.21rem;
  height: calc(100vh - 1.04rem);
  overflow-y: scroll;
  padding-bottom: 0.6rem;
}

.postImageContent.inDesktop::-webkit-scrollbar {
  display: none;
}

.postImageContent .item {
  margin-bottom: 0.3rem;
}

.postImageContent .content {
  padding: 0.09rem 0.12rem 0 0.12rem;
  font-size: 0.14rem;
  line-height: 0.2rem;
  font-weight: 400;
  color: var(--neutral900);
}

.postImageContent .content span {
  font-weight: 600;
}

.postImageContent .noContent {
  width: 100%;
  height: 0.5rem;
}

.postImageContent.inDesktop .item {
  margin-bottom: 0.6rem;
}

.postImageContent.inDesktop .content {
  padding: 0.08rem 0.2rem 0 0.2rem;
  font-size: 0.2rem;
  line-height: 0.28rem;
  font-weight: 400;
}

.postImageContent.inDesktop .noContent {
  height: 0;
}

.reviewsContent > div:first-child .wrapper {
  padding: 0.15rem 0.12rem;
  border-bottom: 0.01rem solid #F5F5F5;
}

.reviewsContent > div:first-child .nickname {
  font-size: 0.14rem;
  line-height: 0.2rem;
}

.reviewsContent > div:first-child .header {
  padding: 0;
}

.reviewsContent > div:first-child .desc {
  font-size: 0.12rem;
  line-height: 0.16rem;
}

.reviewsContent > div:first-child .content {
  font-size: 0.14rem;
  line-height: 0.2rem;
  font-weight: 400;
  color: var(--neutral900);
}

.reviewsContent > div:first-child .contentWrapper {
  margin-top: 0.1rem;
}

.wrapper .postImageGroup {
  padding: 0;
  margin: 0;
}

.wrapper .postImageGroup::-webkit-scrollbar {
  display: none;
}

.wrapper .postImageGroup > *:not(:last-child) {
  margin: 0 !important;
}

.reviewsHeaderWrapper {
  width: 3.75rem;
  height: 1.84rem;
  border-bottom: 0.01rem solid #F5F5F5;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: calc(var(--z-index-modal) + 1);
}

.reviewsHeaderNav {
  width: 3.75rem;
  height: 0.42rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.01rem solid #F5F5F5;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.17rem;
  text-align: center;
  letter-spacing: -0.004rem;
  color: var(--neutral900);
  background-color: #fff;
}

.reviewsHeaderWrapper .reviewsHeaderNav .closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.1436rem;
  line-height: 0.42rem;
  padding: 0 0.12rem;
  color: #4A4A4A;
}

.drawerBodyClass {
  padding-top: 1.84rem !important;
}

.noSearchRes {
  background: #fff url('https://img.flamingo.shop/p/show/7be04ce13bfc4e7b84c10a00e8b41816.png') no-repeat center top 0.5rem;
  background-size: 0.45rem;
  padding-top: 1.05rem;
}

.noSearchRes h1 {
  color: var(--neutral900);
  font-size: 0.14rem;
  font-weight: 500;
}